<template>
    <div class="RangeSlider">
        <div class="Inputs">
            <div class="InputWrap">
                <label for="minValue">Start KM</label>
                <input
                    v-model.number="localMinValue"
                    type="number"
                    :min="min"
                    :max="max"
                    id="minValue"
                    class="Input"
                    @input="updateMinValue"
                />
            </div>
            <div class="InputWrap">
                <label for="maxValue">End KM</label>
                <input
                    v-model.number="localMaxValue"
                    type="number"
                    :min="min"
                    :max="max"
                    id="maxValue"
                    class="Input"
                    @input="updateMaxValue"
                />
            </div>
        </div>
        <div class="Slider">
            <div class="SliderTrack"></div>
            <div class="HighlightedTrack" :style="highlightedTrackStyle"></div>
            <input
                ref="minSlider"
                type="range"
                :min="min"
                :max="max"
                :step="step"
                v-model.number="localMinValue"
                @input="updateMinValue"
                class="range"
            />
            <input
                ref="maxSlider"
                type="range"
                :min="min"
                :max="max"
                :step="step"
                v-model.number="localMaxValue"
                @input="updateMaxValue"
                class="range"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomRangeSlider',
    props: {
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 1000,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            localMinValue: this.min,
            localMaxValue: this.max,
        };
    },
    computed: {
        highlightedTrackStyle() {
            const minPercentage = ((this.localMinValue - this.min) / (this.max - this.min)) * 100;
            const maxPercentage = ((this.localMaxValue - this.min) / (this.max - this.min)) * 100;
            return {
                left: `${minPercentage}%`,
                width: `${maxPercentage - minPercentage}%`,
            };
        },
    },
    watch: {
        min(newVal) {
            this.localMinValue = newVal;
        },
        max(newVal) {
            this.localMaxValue = newVal;
        },
        localMinValue(newVal) {
            if (newVal > this.localMaxValue) {
                this.localMinValue = this.localMaxValue;
            } else {
                this.$emit('update:minValue', newVal);
            }
        },
        localMaxValue(newVal) {
            if (newVal < this.localMinValue) {
                this.localMaxValue = this.localMinValue;
            } else {
                this.$emit('update:maxValue', newVal);
            }
        },
    },
    methods: {
        updateMinValue(event) {
            let value = Number(event.target.value);
            if (value >= this.min && value <= this.localMaxValue) {
                this.localMinValue = value;
            }
        },
        updateMaxValue(event) {
            let value = Number(event.target.value);
            if (value <= this.max && value >= this.localMinValue) {
                this.localMaxValue = value;
            }
        },
    },
};
</script>

<style lang="scss">
.RangeSlider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .Inputs {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        align-items: center;
    }

    .Inputs span {
        padding: 0 10px;
    }

    .Slider {
        position: relative;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .SliderTrack {
        position: absolute;
        width: 100%;
        height: 10px;
        background: #ddd;
        border-radius: 5px;
        top: 50%;
        transform: translateY(-50%);
    }

    .HighlightedTrack {
        position: absolute;
        height: 10px;
        background: var(--color-blue-lighter);
        border-radius: 5px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    input[type='range'] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        background: transparent;
        position: absolute;
        pointer-events: none;
    }

    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: var(--color-blue-dark);
        cursor: pointer;
        border-radius: 50%;
        pointer-events: all;
    }

    input[type='range']::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: var(--color-blue-dark);
        cursor: pointer;
        border-radius: 50%;
        pointer-events: all;
    }

    .InputWrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px !important;

        &.text-right {
            text-align: right;
        }

        label {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .Input {
            padding: 8px;
            border-radius: 5px;
            border: 1px solid var(--color-text-inactive);
            background: var(--color-white);

            &:focus {
                outline: none;
                border-color: var(--color-blue-light);
            }
        }
    }
}
</style>
