var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DriversLog",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', [_c('h1', [_vm._v("Fahrtenbuch")]), _c('p', {
    staticClass: "Description"
  }, [_vm._v(" Verwalten Sie im unteren Bereich Ihre Fahrten. "), _c('br'), _vm._v(" Sie haben "), _c('b', [_vm._v(_vm._s(_vm.unassignedDriversLog.length))]), _vm._v(" Fahrten ohne Zweck. ")])]), _c('Toolbar', [_c('div', [_c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "monthPicker": ""
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleLoadDriversLog
    }
  }, [_vm._v(" Anzeigen ")])], 1), _c('div', [_c('Download', {
    attrs: {
      "title": _vm.isMobile ? 'CSV' : 'Download CSV',
      "type": "csv"
    },
    on: {
      "onDownload": function onDownload($event) {
        return _vm.handleDownload('csv');
      }
    }
  })], 1)]), _c('DriverLogTable', {
    attrs: {
      "driversLog": _vm.driversLog
    },
    on: {
      "onLogItemClick": _vm.handleLogItemClick
    }
  }), _c('Modal', {
    attrs: {
      "title": "Zweck hinzufügen",
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleAddPurpose
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "show": _vm.showPurposeModal,
      "closeOnBackdropClick": false
    },
    on: {
      "onModalClose": _vm.handleHidePurposeModal
    }
  }, [_c('div', {
    staticClass: "Input-Wrap"
  }, [_c('div', [_c('div', {
    staticClass: "InputWrap"
  }, [_c('Dropdown', {
    class: {
      'has-error': _vm.wrongDriver
    },
    attrs: {
      "hasSearch": "",
      "variant": "single-column",
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      }),
      "selected": _vm.selectedLogItem.driverName,
      "placeholder": "Fahrer auswählen"
    },
    on: {
      "onItemSelect": _vm.handleDriverSelect
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Fahrer wird für die Auswertung benötigt.")])], 1), _c('div', {
    staticClass: "InputWrap"
  }, [_c('Dropdown', {
    class: {
      'has-error': _vm.wrongPurpose
    },
    attrs: {
      "hasSearch": "",
      "variant": "single-column",
      "items": _vm.categories.filter(function (c) {
        return c.id !== 'none';
      }),
      "selected": _vm.selectedLogItem.purpose,
      "placeholder": "Kategorie auswählen"
    },
    on: {
      "onItemSelect": _vm.handleCategorySelect
    }
  }), _vm.selectedLogItem.purpose && _vm.selectedLogItem.purpose.id === 'other' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLogItem.purpose.value,
      expression: "selectedLogItem.purpose.value"
    }],
    staticClass: "Input",
    class: {
      'has-error': _vm.wrongPurpose
    },
    attrs: {
      "id": "other",
      "type": "text",
      "placeholder": "Eigene Kategorie",
      "required": ""
    },
    domProps: {
      "value": _vm.selectedLogItem.purpose.value
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.selectedLogItem.purpose, "value", $event.target.value);
      }
    }
  }) : _vm._e(), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Die Kategorie wird für die Auswertung benötigt.")])], 1), _c('div', {
    staticClass: "Row"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.wrongStartDestination
    }
  }, [_c('label', {
    attrs: {
      "for": "from"
    }
  }, [_vm._v("Von")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLogItem.from,
      expression: "selectedLogItem.from"
    }],
    staticClass: "Input",
    attrs: {
      "id": "from",
      "required": ""
    },
    domProps: {
      "value": _vm.selectedLogItem.from
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.selectedLogItem, "from", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.wrongEndDestination
    }
  }, [_c('label', {
    attrs: {
      "for": "to"
    }
  }, [_vm._v("Nach")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLogItem.to,
      expression: "selectedLogItem.to"
    }],
    staticClass: "Input",
    attrs: {
      "id": "to",
      "required": ""
    },
    domProps: {
      "value": _vm.selectedLogItem.to
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.selectedLogItem, "to", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "Row"
  }, [_c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('RangeSlider', {
    attrs: {
      "min": _vm.previousLogItem.startKM,
      "max": _vm.previousLogItem.endKM,
      "step": 1
    },
    on: {
      "update:minValue": _vm.updateStartKM,
      "update:maxValue": _vm.updateEndKM
    }
  }), _c('div', {
    staticClass: "Row"
  }, [_vm.wrongStartKM || _vm.wrongEndKM ? _c('span', {
    staticClass: "Hint"
  }, [_vm._v(" Vorheriger Startwert: " + _vm._s(_vm.previousLogItem.startKM) + " KM ")]) : _vm._e(), _vm.wrongStartKM || _vm.wrongEndKM ? _c('span', {
    staticClass: "Hint"
  }, [_vm._v(" Vorheriger Endwert: " + _vm._s(_vm.previousLogItem.endKM) + " KM ")]) : _vm._e()])], 1)]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "notice"
    }
  }, [_vm._v("Notiz")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLogItem.notice,
      expression: "selectedLogItem.notice"
    }],
    staticClass: "Input",
    attrs: {
      "id": "notice"
    },
    domProps: {
      "value": _vm.selectedLogItem.notice
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.selectedLogItem, "notice", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Fügen Sie hier eine Beschreibung hinzu.")])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }