var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "RangeSlider"
  }, [_c('div', {
    staticClass: "Inputs"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "minValue"
    }
  }, [_vm._v("Start KM")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.localMinValue,
      expression: "localMinValue",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "Input",
    attrs: {
      "type": "number",
      "min": _vm.min,
      "max": _vm.max,
      "id": "minValue"
    },
    domProps: {
      "value": _vm.localMinValue
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.localMinValue = _vm._n($event.target.value);
      }, _vm.updateMinValue],
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "maxValue"
    }
  }, [_vm._v("End KM")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.localMaxValue,
      expression: "localMaxValue",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "Input",
    attrs: {
      "type": "number",
      "min": _vm.min,
      "max": _vm.max,
      "id": "maxValue"
    },
    domProps: {
      "value": _vm.localMaxValue
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.localMaxValue = _vm._n($event.target.value);
      }, _vm.updateMaxValue],
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]), _c('div', {
    staticClass: "Slider"
  }, [_c('div', {
    staticClass: "SliderTrack"
  }), _c('div', {
    staticClass: "HighlightedTrack",
    style: _vm.highlightedTrackStyle
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.localMinValue,
      expression: "localMinValue",
      modifiers: {
        "number": true
      }
    }],
    ref: "minSlider",
    staticClass: "range",
    attrs: {
      "type": "range",
      "min": _vm.min,
      "max": _vm.max,
      "step": _vm.step
    },
    domProps: {
      "value": _vm.localMinValue
    },
    on: {
      "input": _vm.updateMinValue,
      "__r": function __r($event) {
        _vm.localMinValue = _vm._n($event.target.value);
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.localMaxValue,
      expression: "localMaxValue",
      modifiers: {
        "number": true
      }
    }],
    ref: "maxSlider",
    staticClass: "range",
    attrs: {
      "type": "range",
      "min": _vm.min,
      "max": _vm.max,
      "step": _vm.step
    },
    domProps: {
      "value": _vm.localMaxValue
    },
    on: {
      "input": _vm.updateMaxValue,
      "__r": function __r($event) {
        _vm.localMaxValue = _vm._n($event.target.value);
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }